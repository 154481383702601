import { useState, useEffect, useCallback } from 'react';
import {
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Box,
  Typography,
  Divider,
  Button,
  Tooltip,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import { getNearbyReports } from 'services/getNearbyReports';
import { useTranslation } from 'react-i18next';
import {
  getUserLocationbyGeo,
  fetchUserLocationByIP,
} from 'services/getUserLocation';
import { reportDateTime } from 'utils/constants';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNearbyReports,
  setNearbyReports,
} from 'redux/report/nearbyReportsSlice';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const NotificationIcon = ({ isUserLocationPermission, userId }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayedRows] = useState(3);
  const [displayAllReports, setDisplayAllReports] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const { nearbyReports } = useSelector(selectNearbyReports);
  const reportsCount = nearbyReports?.length;

  const fetchReports = useCallback(
    async location => {
      if (!location || !location.lat || !location.lng) return;
      try {
        const unsubscribe = await getNearbyReports(
          location.lat,
          location.lng,
          500,
          userId,
          reports => dispatch(setNearbyReports(reports))
        );
        return typeof unsubscribe === 'function' ? unsubscribe : null;
      } catch (error) {
        console.error('Error fetching reports:', error);
        return null;
      }
    },
    [userId, dispatch]
  );

  // Fetch user location on component mount or when location permission changes
  const updateLocation = useCallback(async () => {
    try {
      //  IP
      const ipLocation = await fetchUserLocationByIP();
      setUserLocation(ipLocation);
      //  geolocation
      if (navigator.geolocation) {
        try {
          const geoLocation = await getUserLocationbyGeo();
          setUserLocation(geoLocation);
        } catch (geoError) {
          console.warn('Geolocation error:', geoError);
        }
      }
    } catch (error) {
      console.error('Error updating location:', error);
    }
  }, []);

  useEffect(() => {
    if (isUserLocationPermission) {
      updateLocation();
    }
  }, [isUserLocationPermission, updateLocation]);

  // Handle geolocation permission changes
  useEffect(() => {
    if (!navigator.permissions) return;
    const handlePermissionChange = async permissionStatus => {
      if (permissionStatus.state === 'granted') {
        await updateLocation();
      }
    };

    navigator.permissions
      .query({ name: 'geolocation' })
      .then(permissionStatus => {
        permissionStatus.onchange = () =>
          handlePermissionChange(permissionStatus);
      });
  }, [updateLocation]);

  // Fetch and subscribe to reports when userLocation changes
  useEffect(() => {
    let unsubscribe = null;

    if (userLocation) {
      const fetchAndSubscribe = async () => {
        unsubscribe = await fetchReports(userLocation);
      };
      fetchAndSubscribe();
    }

    return () => {
      if (typeof unsubscribe === 'function') unsubscribe();
    };
  }, [fetchReports, userLocation]);

  const handleMenuOpen = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleViewAll = () => setDisplayAllReports(!displayAllReports);

  return (
    <>
      <Tooltip title={t('ui:notifications.title')}>
        <span>
          <IconButton
            id="notification-button"
            aria-controls={isMenuOpen ? 'notification-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleMenuOpen}
            disabled={reportsCount === 0}
          >
            <StyledBadge badgeContent={reportsCount} color="primary">
              <NotificationsIcon />
            </StyledBadge>
          </IconButton>
        </span>
      </Tooltip>
      {anchorEl && nearbyReports && (
        <Menu
          id="notification-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiAvatar-root': {
              minWidth: 240,
              padding: 2,
              bgcolor: '#ffff',
              backgroundImage: 'none',
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        >
          <Box
            bgcolor={'#0000'}
            flexDirection={'column'}
            alignItems={'normal'}
            p={2}
          >
            <Typography fontWeight={600} textDecoration="underline">
              {t('ui:appBar.resourceNotify')}
            </Typography>
            {nearbyReports
              .slice(
                0,
                displayAllReports ? nearbyReports.length : displayedRows
              )
              .map(report => (
                <Box key={report.reportId}>
                  <MenuItem
                    sx={{ width: '100%' }}
                    component={Link}
                    to="/account/notifications"
                    onClick={() => {
                      handleMenuClose();
                    }}
                  >
                    <Box flexDirection={'column'} alignItems={'normal'}>
                      <Typography>
                        {t(
                          `hazards:hazard.${report.reportType}.items.${report.hazardType}`
                        )}
                      </Typography>
                      <Typography variant="caption">
                        {reportDateTime(report.timeObserved.seconds * 1000)}
                      </Typography>
                    </Box>
                  </MenuItem>
                  <Divider orientation="horizontal" />
                </Box>
              ))}
            {nearbyReports.length > 3 && (
              <Button
                sx={{ display: 'block', marginLeft: 'auto' }}
                onClick={handleViewAll}
              >
                {displayAllReports
                  ? t('ui:button.hide')
                  : t('ui:button.viewAll')}
              </Button>
            )}
          </Box>
        </Menu>
      )}
    </>
  );
};

export default NotificationIcon;
