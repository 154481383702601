import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import languageReducer from './language/languageSlice';
import reportReducer from './report/reportSlice';
import updateReportReducer from './report/updateReportSlice';
import currentPathReducer from './redirectPath/currentPathSlice';
import nearbyReportsReducer from './report/nearbyReportsSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  user: authReducer,
  language: languageReducer,
  report: reportReducer,
  selectedReport: updateReportReducer,
  nearbyReports: nearbyReportsReducer,
  currentPath: currentPathReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['nearbyReports'],
        ignoredActionPaths: ['payload'],
      },
    }),
});

export const persistor = persistStore(store);
